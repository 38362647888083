import axios from '@/http'
const iotReport = {
    getReportDetail(data) {
        return axios({
          method: 'GET',
          url: 'https://myapi.xaunqd.com/api/openapi/report/v1/reportDetail',
          params: data,
        })
    }
}
export default iotReport