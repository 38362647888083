<template>
  <div id="app">
    <router-view />
      <div>
        <!-- pc -->
        <div v-if="mobileShow && phoneConsultShow" class="pcIcon_box">
          <ul class="pcIcon">
            <li @click="handle53" id="consult-left">
              <img src="./assets/home/consulting.png" alt="">
               <p>在线咨询</p>
            </li>
            <li class="phone_box" @mouseenter="phoneEnter" @mouseleave="phoneLeave">
                <img src="./assets/home/phone25.png" alt="">
                <p>电话咨询</p>
                <div v-if="isPhoneNum" class="phone_bg">
                  <p
                    style="font-size: 22px;
                          font-family: DINPro;
                          font-weight: 700;
                          color: #1e203c;
                          margin-top: 16px;
                          margin-left: 13px;
                          "
                  >400-800-1252</p>
                  <p
                    style="font-size: 12px;
                          font-family: PingFang SC;
                          font-weight: 400;
                          color: #1e203c;
                          margin-top: -5px;
                          margin-left: 10px;"
                  >全国统一24小时服务热线</p>
                </div>
              </li>
            <li @click="handleConsult" id="enquiry-left">
              <img src="./assets/home/message.png" alt="">
              <p>自助询价</p>
            </li>
<!--            <li class="orcode_box" @mouseenter="orCodeEnter" @mouseleave="orCodeLeave">-->
<!--              <img src="./assets/home/orCode.png" alt="">-->
<!--              <p>扫码关注</p>-->
<!--              <div v-if="isOrcode" class="orcode_bg">-->
<!--                <img :src="$path + orCode_img" alt="" width="126px" height="126px" style="">-->
<!--                <p-->
<!--                  style="font-size: 14px;-->
<!--                        font-family: PingFangHK, PingFangHK-Regular;-->
<!--                        font-weight: 400;-->
<!--                        color: #64686e;"-->
<!--                >{{orCode_title}}</p>-->
<!--              </div>-->
<!--            </li>-->
            <li @click="handleBackTop">
              <img src="./assets/home/to-top.png" alt="">
              <p>回到顶部</p>
            </li>
          </ul>
        </div>
        <!-- 移动端 -->
      <div v-else class="phoneIcon_box">
<!--        <ul :class="isphoneIconShow ? 'phoneIcon_show' : 'phoneIcon_close'">-->
<!--          <li>-->
<!--            <a href="tel:4008001252">-->
<!--              <img src="./assets/home/mobile/41@2x.png" alt="">-->
<!--            </a>-->
<!--          </li>-->
<!--          <li @click="handleHalf532">-->
<!--            <img src="./assets/home/mobile/39@2x.png" alt="">-->
<!--          </li>-->
<!--          <li @click="handleConsult">-->
<!--            <img src="./assets/home/mobile/38@2x.png" alt="">-->
<!--          </li>-->
<!--        </ul>-->
        <div class="phoneIcon" @click="handleHalf532"  v-if="phoneConsultShow">
            <!-- <img v-if="!isphoneIconShow"  src="./assets/home/mobile/17@2x.png" alt="">-->
            <!-- <img v-else src="./assets/home/mobile/31@2x.png" alt="">-->
            <img  src="./assets/home/mobile/customer-service-icon.png" @click="handleHalf532" alt="">
        </div>
      </div>
      </div>
      <consultForm  ref="consultForm" />
      <div class="footer-operator" id="footer-operator-app" v-if="!mobileShow && phoneConsultShow">
          <div class="footer-operator-concat">
              <img @click="handle532"  class="footer-operator-item-icon" src="@/assets/home/mobile/footer-concat-icon.png" alt="">
              <div class="opt-desc">在线咨询</div>
          </div>
          <div class="footer-operator-phone">
            <img class="call-phone-icon" src="@/assets/home/mobile/footer-phone-icon.png" alt="">
            <div class="opt-desc">电话</div>
            <a href="tel:4008001252" class="call-phone">
            </a>
          </div>
      </div>
  </div>
</template>

<script>
  import {onlineConsulting,
    onlineConsultingHalf,
    onlineMobileConsultingHalf} from '@/unit/common'
  (function() {
      // 22222222
      // var _53code = document.createElement("script");
      // _53code.src = "https://tb.53kf.com/code/code/5cd60bbe34341ac131de2c0cddeef983/3";
      // var s = document.getElementsByTagName("script")[0];
      // s.parentNode.insertBefore(_53code, s);
  }
  )();
import { mapState, mapMutations } from 'vuex'
export default {
  components: {
    consultForm: () => import("@/components/consult"),
  },

  data() {
    return {
      isphoneIconShow: false,
      isPhoneNum: false,
      isOrcode: false,
      orCode_img: '',
      orCode_title: '',
      phoneConsultShow: true
    };
  },
  watch: {
    $route: function (to, from) {
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
      if (this.$route.query.toDetailStatus && this.$route.query.toDetailStatus == 1) {
        window.console.log("互相跳转");
      } else {
        if (this.$route.query.status != "aaa") {
          if (from.path != "/") {
            if (from.path != "/search") {
              if (from.path != "/home") {
                if (from.path != "/footerblank") {
                  window.localStorage.removeItem("defaultActive");
                  window.localStorage.removeItem("listVal");
                }
              }
            }
          }
        }
        // this.$store.commit("changeMenu", {
        //   id: 0,
        //   name: "",
        // });
      }
    },
    orCodeImg(val){
      this.orCode_img = val
    },
    orCodeTitle(val){
      this.orCode_title = val
    }
  },
    computed: {
    ...mapState(['screenWidth','orCodeImg','orCodeTitle']),
      mobileShow() {
      if (this.screenWidth < 1000) {
          return false
      } else {
          return true
      }
      }
    },
  created() {
    console.log('isWeiXin',window.wx)
    console.log('isWeiXin()',this.isWeiXin())
    if(window.location.href.indexOf('jinZhiCertification') > -1) {
      this.phoneConsultShow = false
    } else {
      this.phoneConsultShow = true
    }
  },
  mounted() {
    this.$api.iotHome
      .getServiceList({
        type: 1
      })
      .then(res => {
         if (res.data.success == true) {
          this.$store.commit("changeServiceMenuList", res.data.data);
         }
      })
    this.$api.iotHome
      .typelist()
      .then((res) => {
        if (res.data.success == true) {
          this.$store.commit("changeProductTypeList", res.data.data);
          window.sessionStorage.setItem("productTypeList", JSON.stringify(res.data.data));
          window.localStorage.setItem("detaList", JSON.stringify(res.data.data));
        }
      });
    // if(this.isWeiXin()) {
    //   //公众号内自定义分享
    //   window.wx.config({
    //     debug: true, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
    //     appId: "wx45e53912acae48e3", // 必填，公众号的唯一标识
    //     timestamp: 1625215394, // 必填，生成签名的时间戳
    //     nonceStr: "V99ks5XBnh", // 必填，生成签名的随机串
    //     signature: "18f86d58a148e82b1f4f5928022f99057153dfef", // 必填，签名
    //     jsApiList: ['onMenuShareAppMessage', 'onMenuShareTimeline'] // 必填，需要使用的JS接口列表
    //   });
    //   window.wx.ready(function () {
    //    console.log('ready')
    //   });
    //   window.wx.error(function () {
    //     console.log('error')
    //   });
    // }

    // window.onresize = () => {
    //   this.setWidth(document.documentElement.clientWidth)
    // }
    // this.orCode_img = window.localStorage.getItem('orCode_img')
    // this.orCode_title = window.localStorage.getItem('orCode_title')
  },
  methods: {
    isWeiXin(){
      // var ua = window.navigator.userAgent.toLowerCase();
      // if (ua.match(/MicroMessenger/i) == 'micromessenger') {return true;} else {return false;}
    },
    callPhone(){
        let a = document.createElement("a")
        a.setAttribute("href", `tel:${this.officeTelephone}`)
        a.click()
    },
    phoneIconOpen() {
      this.isphoneIconShow = !this.isphoneIconShow
    },
    handle53() {
      const specs="height=600,width=1000,alwaysRaised,directories=no,toolbar=no,titlebar=no,menubar=no,scrollbars=no,left=300,top = 200, resizable=no,location=no, status=no"
      window.open("https://tb.53kf.com/code/client/5cd60bbe34341ac131de2c0cddeef983/3","_blank",specs)
    },

    handleHalf532(){
      const specs="height=600,width=1000,alwaysRaised,directories=no,toolbar=no,titlebar=no,menubar=no,scrollbars=no,left=300,top = 200, resizable=no,location=no, status=no"
      window.open("https://tb.53kf.com/code/wx/5cd60bbe34341ac131de2c0cddeef983/3","_blank",specs)
      // onlineMobileConsultingHalf()
      //   var _53 = $53.createApi();      /*开发者文档1.1 创建SDK资源对象*/
      //   _53.push('cmd', 'mtalk');       /*开发者文档1.7 主动发起(手机端)*/
      //   _53.query();
    },
    handle532() {
      const specs="height=600,width=1000,alwaysRaised,directories=no,toolbar=no,titlebar=no,menubar=no,scrollbars=no,left=300,top = 200, resizable=no,location=no, status=no"
      window.open("https://tb.53kf.com/code/wx/5cd60bbe34341ac131de2c0cddeef983/3","_blank",specs)
    },
    // 咨询表单
    handleConsult() {
      const consultForm = this.$refs.consultForm
      consultForm.$data.dialogVisible = true
    },
    // 回到顶部
    handleBackTop() {
      document.body.scrollTop = document.documentElement.scrollTop = 0;
    },
    phoneEnter() {
      this.isPhoneNum = true
    },
    phoneLeave() {
      this.isPhoneNum = false
    },
    orCodeEnter() {
      this.isOrcode = true
    },
    orCodeLeave() {
       this.isOrcode = false
    }
  },
};
</script>

<style lang="scss">
@import "style/common";
@import "style/font";
@import "style/func";
@import "style/detection.css";

html,
body,
#app {
  width: 100%;
  height: 100%;
  background: white;
}

.el-dialog__wrapper {
  z-index: 9999 !important;
}
.el-dialog__body {
  padding: 0 !important;
}
.el-dialog {
  width: 25% !important;
}
.el-image-viewer__close{
    color:#fff !important;
}
.phoneIcon_box {
    position: fixed;
    top: 80%;
    right: 20px;
    transform: translateY(-80%);
    z-index: 9999;
    ul {

      li {
        width: 48px;
        height: 48px;
        margin-bottom: 12px;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
    .phoneIcon {
      width: 40px;
      height: 40px;
      img {
         width: 100%;
        height: 100%;
      }
    }
  }
  .phoneIcon_show {
    display: block;
  }
  .phoneIcon_close {
    display: none;
  }
  .pcIcon_box {
    position: fixed;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    z-index: 9999;
    .pcIcon {
        text-align: center;
      li {
        width: 68px;
        height: 68px;
        border-bottom: 1px solid #fff;
        background: #122266;
        font-family: PingFangSC-Medium;
        font-size: 12px;
        color: #FFFFFF;
        font-weight: 500;
        text-align: center;
        padding: 13px 0;
        cursor: pointer;
        &:last-child {
          border: none;
        }
        img {
          margin: 0 auto 5px auto;
        }
      }
      .phone_box {
        position: relative;
        .phone_bg {
          background: url('./assets/home/phoneBg.png') no-repeat;
          width: 211px;
          height: 90px;
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          right: -210px;
        }
      }
      .orcode_box {
         position: relative;
        .orcode_bg {
          background: url('./assets/home/orCodeBg.png') no-repeat;
          width: 197px;
          height: 204px;
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          right: 70px;
          img {
            margin: 25px auto;
          }
          p {
            margin-top: -25px
          }
        }
      }
    }
  }
  .footer-operator{
    position: fixed;
    z-index: 1000000;
    bottom: 0px;
    width: 100%;
    height: 60px;
    background: #F3F4F9;
    padding: 8px 15px 7px;
    display: flex;
    justify-content: space-between;
    .footer-operator-concat {
      width: 27%;
      .opt-desc {
        font-size: 15px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #000000;
        line-height: 16px;
        text-align: center;
        margin-top: 4px;
      }
    }
    .footer-operator-item-icon {
      display: block;
      width: 23px;
      height: 24px;
      margin: 0 auto;

    }
    .footer-operator-phone {
      display: block;
      width: 73%;
      height: 45px;
      display: flex;
      justify-content: center;
      align-items: center;
      background: linear-gradient(-90deg, #1441FF 0%, #4065FF 100%);
      border-radius: 5px;
      position: relative;
      a {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
      }
      .call-phone-icon {
        width: 15px;
        height: 19px;
        display: block;
        margin-right: 11px;
      }
      .opt-desc {
        font-size: 18px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #FFFFFF;
      }
    }
  }

</style>
